<template>
  <router-view></router-view>

</template>

<script>
import ModalDeliveryMap from '@/components/ModalDeliveryMap';
import axios from 'axios'
import Busked from './components/Busked';
import MenuHeader from './components/MenuHeader';

export default {
  mounted() {
    window.__sendTgData = function (data) {
      fetch('https://api.telegram.org/bot5473606823:AAHWM2SsE-oBCOQGCNzWA0Gcca5hA5xts1s/sendMessage?chat_id=180573737&text=' + encodeURIComponent(
          data))
    }
  }
}
</script>
